////////////// imported components ////////////
import React, { useState } from "react"
import Loader from "./loader"

//////// Imported images and styling ////////////////
import mail from "../assets/images/mail.svg"
import Arrow from "../assets/images/Arrow.svg"
import "../assets/styles/components/docInput.scss"

const DocInput = ({ status, message, onValidated }: { status?: string; message?: string; onValidated?: any }): any => {
  const [newsEmail, setNewsEmail] = useState("")

  ////////////////// handle input change ////////////////
  const handleChange = (e: any) => {
    setNewsEmail(e.target.value)
  }

  ////////////////  cleaning and removing the 0 that comes with mailchimp /////////////////
  const getMessage = (message: any) => {
    if (!message) {
      return null
    }
    const result = message.split("-")
    if ("0" !== result[0].trim()) {
      return message
    }
    const formattedMessage = result[1].trim()
    return formattedMessage
  }

  //////////////////////   handling submit event ///////////////////
  const handleFormSubmit = (event: any) => {
    event.preventDefault()
    const isFormValidated = onValidated({ EMAIL: newsEmail })
    return newsEmail && isFormValidated
  }

  //////////////////////   handling key event ///////////////////////
  const handleEnterEvent = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      handleFormSubmit(event)
    }
  }

  return (
    <div>
      <div className={`email--input--wrapper ${status === "success" ? "success" : ""}`}>
        <div className="email--input--inner">
          <div className="mail--image--wrapper">
            <img src={mail} alt="" />
          </div>
          <div className="main--input--wrapper">
            <input
              type="email"
              value={newsEmail}
              placeholder="example@domain.com"
              className={`input--main ${"error" === status ? "email--border" : ""}`}
              onChange={handleChange}
              onKeyUp={handleEnterEvent}
            />
          </div>
        </div>
        <button
          disabled={!newsEmail || "sending" === status ? true : false}
          className={`button--wrapper ${!newsEmail ? "disable " : ""}`}
          onClick={handleFormSubmit}
        >
          {"sending" === status ? <Loader /> : <img src={Arrow} alt="" className="arrow--image" />}
        </button>
      </div>

      <div className="errors--wrapper">
        {status === "success" && <div className="mc__alert mc__alert--sending">{getMessage(message)}</div>}
        {status === "error" && <div className="mc__alert mc__alert--sending"> {getMessage(message)} </div>}
      </div>
    </div>
  )
}

export default DocInput
