import React, { useState } from "react"
import BrooiLogoBlack from "../assets/images/brooi_icon_black.png"

import "../assets/styles/components/doc-header.scss"
import { Link } from "gatsby"

const IndexPage = () => {
  const [click, setClick] = useState(false)
  const [navActive, setNavActive] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = (e: React.MouseEvent<HTMLElement>) => {
    setClick(false)
    setNavActive(true)
  }
  return (
    <>
      <header className="header-nav">
        <Link to="/doc" className="nav-left">
          <div className="brooi-logo--wrapper">
            <img src={BrooiLogoBlack} alt="Brooi Logo" className="brooi-logo" />
          </div>
          <div className="brooi--logo--header">
            <span>blog</span>
          </div>
        </Link>
        <nav className={`nav-links ${click ? "active" : ""}`}>
          <Link to="/doc/article/doc-article" className={`nav-link-item `} onClick={closeMobileMenu}>
            Articles
          </Link>
          <Link to="/doc/event/doc-events" className={`nav-link-item`} onClick={closeMobileMenu}>
            Events
          </Link>
          <Link to="/doc/video/doc-videos" className="nav-link-item" onClick={closeMobileMenu}>
            Videos
          </Link>
          <Link to="/doc/project/doc-projects" className="nav-link-item" onClick={closeMobileMenu}>
            Projects
          </Link>
          <Link to="/doc/search/doc-search" className="search--icon">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#2b2b2b"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0004 20.9999L16.6504 16.6499"
                stroke="#2b2b2b"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </nav>
        <div className={`hamburger ${click ? "open" : ""}`} onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    </>
  )
}

export default IndexPage
