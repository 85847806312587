import React, { useEffect, useState } from "react"
import "../../assets/styles/page/docindex.scss"
import { Link } from "gatsby"
import ArticleCard from "../../components/ArticleCard"
import Landingnotice from "../../components/landingnotice"
import Layout from "../../components/layout"
import SEO from "../../components/seo/seo"

interface ContentItem {
  titleOfArticle?: string
  titleOfProject?: string
  videoTitle?: string
  bannerUrl?: {
    url: string
  }
  videoThumbnail?: {
    url: string
  }
  metaTitle?: string
  projectDescription?: string
  contentCategory: string
  metaDescription?: string
  videoCategory?: string
  ProjectCategory?: string
  _id: string
  slug: string
}

interface FormattedItem {
  title: string
  image: string
  subtitle: string
  contentCategory: string
  category: string
  id: string
  slug: string
}

interface MetaInfo {
  title: string
  description: string
  image: {
    url: string
  }
}

interface LandingNotice {
  landingNoticeTitle: string
  landingNoticeSubtext: string
  landingNoticeMeta: string
  slug: string
  landingBanner: {
    url: string
  }
}

const LandingPage = () => {
  const [list, setList] = useState<FormattedItem[]>([])
  const [newList, setNewList] = useState<FormattedItem[]>([])
  const [metaInfo, setMetaInfo] = useState<MetaInfo | null>(null)
  const [landingNotice, setLandingNotice] = useState<LandingNotice | null>(null)
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    const fetchContent = async () => {
      const query = `
        query {
          landingPageCollection(limit: 50) {
            items {
              allContentListCollection {
                ... on LandingPageAllContentListCollection {
                  items {
                    ... on Article {
                      _id
                      metaDescription
                      metaTitle
                      titleOfArticle
                      bannerUrl {
                        url
                      }
                      slug
                      contentCategory
                    }
                    ... on Project {
                      _id
                      titleOfProject
                      slug
                      projectCategory
                      projectDescription
                      bannerUrl {
                        url
                      }
                      contentCategory
                    }
                    ... on Video {
                      _id
                      videoTitle
                      videoCategory
                      metaTitle
                      slug
                      videoThumbnail {
                        url
                      }
                      contentCategory
                    }
                  }
                }
              }
              seo {
                ... on Seo {
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
          landingNoticeCollection {
            items {
              landingNoticeTitle
              landingNoticeSubtext
              landingNoticeMeta
              slug
              landingBanner {
                url
              }
            }
          }
        }
      `
      try {
        const { default: ContentfulService } = await import("../../services/contentful.service")
        const response = await ContentfulService.callContentfulApi(query)

        const landingPageData = response.data.landingPageCollection?.items[0]
        const landingNoticeData = response.data.landingNoticeCollection?.items[0]

        if (landingPageData?.allContentListCollection?.items) {
          const allContentfulList = landingPageData.allContentListCollection.items.map((article: ContentItem) => ({
            title: article.titleOfArticle || article.titleOfProject || article.videoTitle || "",
            image: article.bannerUrl?.url || article.videoThumbnail?.url || "",
            subtitle: article.metaTitle || article.projectDescription || "",
            contentCategory: article.contentCategory,
            category: article.metaDescription || article.videoCategory || article.ProjectCategory || "",
            id: article._id,
            slug: getSlugPath(article)
          }))

          setList(allContentfulList)
          setNewList(allContentfulList.slice(0, 8))
        }

        if (landingPageData?.seo) {
          setMetaInfo(landingPageData.seo)
        }

        if (landingNoticeData) {
          setLandingNotice(landingNoticeData)
        }
      } catch (error) {
        console.error("Error fetching content:", error)
      }
    }

    fetchContent()
  }, [])

  const getSlugPath = (item: ContentItem) => {
    switch (item.contentCategory) {
      case "article":
        return `/doc/article/doc-article/${item.slug}`
      case "project":
        return `/doc/project/doc-projects/${item.slug}`
      case "video":
        return `/doc/article/doc-videos/${item.slug}`
      default:
        return ""
    }
  }

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    const isMore = newList.length < list.length
    setHasMore(isMore)
  }, [newList, list])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = newList.length
      const isMore = currentLength < list.length
      const nextResults = isMore ? list.slice(currentLength, currentLength + 6) : []
      setNewList([...newList, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore, list, newList])

  if (!metaInfo || !landingNotice) {
    return <div>Loading...</div>
  }

  return (
    <div className="doc--landing--page--wrapper">
      <SEO title={metaInfo.title} description={metaInfo.description} image={metaInfo.image.url} />

      <Layout>
        <div className="docs--content--container">
          <div className="landing--page--banner">
            <Link to={`/doc/${landingNotice.slug}`} key={landingNotice.landingNoticeSubtext}>
              <Landingnotice
                bannerImage={landingNotice.landingBanner.url}
                NoticeHeadtext={landingNotice.landingNoticeTitle}
                NoticeSubtext={landingNotice.landingNoticeSubtext}
                metaTitle={landingNotice.landingNoticeMeta}
              />
            </Link>
          </div>
          <div className="All--article--list">
            {newList.map(item => {
              const { title, subtitle, category, image, slug, id } = item
              return (
                <Link to={slug} className="all-artile--card--wrapper" key={slug}>
                  <ArticleCard
                    key={id}
                    articleImage={image}
                    articleHeadtext={title}
                    articleSubtext={subtitle}
                    meta={category}
                  />
                </Link>
              )
            })}
          </div>

          <div className="load--more--button">{hasMore && <button onClick={handleLoadMore}>Load More</button>}</div>
        </div>
      </Layout>
    </div>
  )
}

export default LandingPage
