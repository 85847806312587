import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import DocInput from "../components/docInput"

const DocMailchimp = () => {
  const MAILCHIMP_URL: string = process.env.MAILCHIMP_PUBLIC_URL || ""
  return (
    <div>
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={(props: any) => {
          const { subscribe, status, message } = props
          return <DocInput status={status} message={message} onValidated={(formData: any) => subscribe(formData)} />
        }}
      />
    </div>
  )
}

export default DocMailchimp
