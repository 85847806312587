import React, { Children } from "react"
import DocHeader from "../components/docheader"
import DocFooter from "../components/docfooter"
import "../assets/styles/components/layout.scss"
import Footerstrip from "./footerstrip"

const Layout = ({ children }) => {
  return (
    <div>
      <nav className="header-doc-wrapper">
        <DocHeader />
      </nav>
      <div>{children}</div>
      <div className="footer-wrapper">
        <DocFooter />
      </div>
      <div className="footer--strip--wrapper">
        <Footerstrip />
      </div>
    </div>
  )
}

export default Layout
