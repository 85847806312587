import React from "react"
import "../assets/styles/components/docFooter.scss"
import DocMailchimp from "./docMailchimp"

const DocFooter = () => {
  return (
    <>
      <div className="footer--container">
        <div className="footer--text">
          Keep up to date with Brooi's creative team-Sign up for our newsletter and stay informed
        </div>
        <DocMailchimp />
      </div>
    </>
  )
}

export default DocFooter
